/* Apply external material css */
.App {
  font-family: sans-serif;
  text-align: center;
  color: #282c34;
  width: 100vw;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.rmdp-container {
  width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #6f48eb;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-select__tile {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: left;
  align-items: left;
  line-height: 16px;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: #646269;
  border-radius: 6px;
  font-weight: bold;
  color: #fff;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
}

.react-calendar__tile--hasActive {
  background: #6f48eb;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #bfb3e8;
  color: #6f48eb;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
}

p,
h2,
footer {
  display: flex;
  justify-content: center;
}

select,
input {
  width: 220px;
  padding: 0;
  margin: 0;
}

legend {
  font-size: 60%;
}

fieldset {
  width: 250px;
  bottom: 0;
  height: 33px;
  padding: 0px;
  margin-left: 508px;
  color: grey;
  border: 1px solid grey;
}

input,
select {
  /* border: none; */
  width: 95%;
  padding: 3px;
  position: static;
}

footer #calendar {
  background-color: #c70039;
  color: white;
  width: 130px;
  height: 35px;
}

.calendar {
  margin-left: 38%;
}

#calendar:hover {
  background-color: darkred;
  cursor: pointer;
}

.logout--button {
  margin-top: 15px;
  background-color: white;
  cursor: pointer;
  border: 1px solid grey;
  height: 30px;
  width: 68px;
}

header {
  background-color: #c70039;
  height: 50px;
}

div.btns {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  padding: 10px;
  grid-gap: 10px;
  width: 280px;
  margin-left: 30%;
}

.grid-button>button {
  border: 1px solid grey;
  height: 30px;
  width: 68px;
  background-color: white;
  color: grey;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  padding: 6px;
  border-radius: 9%;
}

.grid-button7>button {
  border: 1px solid grey;
  width: 68px;
  height: 30px;
  padding: 10px;
  background-color: white;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50%;
  border-radius: 9%;
}

div>button {
  margin: auto 7px 5px 7px;
}

.grid-button7>button {
  margin: auto auto auto 49%;
}

.grid-button>button:hover,
.grid-button7>button:hover {
  color: black;
  cursor: pointer;
}

.text {
  margin: 0;
}

.rmsc .dropdown-heading {
  max-width: 300px;
}

.rmsc .item-renderer input {
  width: fit-content;
}

#root>div>div:nth-child(3)>div:nth-child(2)>div.col>div>div.css-zmtpmk>div>div:nth-child(6)>div.css-1fgdw62>div:nth-child(2)>div>div.css-qa8vj3>div,
.rmsc,
.multi-select {
  width: 100%;
}

#root>div>div:nth-child(3)>div:nth-child(2)>div.col>div>div.css-zmtpmk>div>div:nth-child(6)>div.css-11lvvg3>div:nth-child(2)>div>div>div.css-b62m3t-container {
  width: 100%;
}

.jJLOff .blockbuilder-branding {
  display: none !important;
}