.sidebar-wrapper {
  background-color: #00384e;
  color: #fff;
  position: fixed;
  height: 100%;
  display: flex;
  left: 0;
  margin-top: 100px;
  z-index: 5;
  min-width: 270px;
  transform: translateX(-100%);
  font-family: sans-serif;
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 720px) {
  .sidebar-wrapper {
    margin-top: 64px;
  }
}

@media screen and (max-width: 599px) {
  .sidebar-wrapper {
    margin-top: 56px;
  }
}

.sidebar-active {
  transform: translateX(0%);
  z-index: 5;
}

#sidebar-inner-wrapper {
  min-width: 270px !important;
  width: 270px !important;
}

.sidebar-menu-wrapper {
  font-weight: bold;
  font-size: 16px;
}

#sidebar-username-wrapper {
  font-weight: bold;
  font-size: 18px;
}

.sidebar-menu-icon {
  font-size: 22px;
}

.sidebar-user-icon {
  font-size: 30px;
}

.sidebar-item-label {
  margin-left: 10px;
}

.sidebar-user-label {
  margin-left: 10px;
}
