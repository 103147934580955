.sideNav-container {
    // display: flex;
    // height: 500px;
    width: 250px;
    position: fixed;
    z-index: 999 !important;
    right: 0px;
    top: 100px;
    background-color: white;
    border: 5px solid #f2f2f2;
    flex-direction: column;
    align-items: flex-end;
}

.sideNav-section {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    border-bottom: 0.1px solid #f2f2f2;
    padding-top: 10px;
    padding-left: 10px;
    // align-items:flex-end;
    justify-content: left;
    padding-bottom: 10px;
    margin-top: 10px;
}

.sideNav__button {
    text-decoration: none;
    color: white;
}

.sidebar-wrapper {
    background-color: #00384e;
    color: #fff;
    // position: absolute;
    // height: 90%;
    display: flex;
    right: 0;
    margin-top: 100px;
    z-index: 99;
    width: 170px;
    font-family: 'Open Sans';
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 720px) {
    .sidebar-wrapper {
        margin-top: 64px;
    }
}

@media screen and (max-width: 599px) {
    .sidebar-wrapper {
        margin-top: 56px;
    }
}

.sidebar-active {
    transform: translateX(0%);
    z-index: 5;
}

#sidebar-inner-wrapper {
    min-width: 270px !important;
    width: 270px !important;
}

.sidebar-menu-wrapper {
    font-weight: bold;
    font-size: 16px;
}

#sidebar-username-wrapper {
    font-weight: bold;
    font-size: 14px;
}

.sidebar-menu-icon {
    font-size: 14px;
}

.sidebar-user-icon {
    font-size: 30px;
}

.sidebar-item-label {
    margin-left: 0px;
}

.sidebar-user-label {
    margin-left: 0px;
}